import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "crescent" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "crescent-elcyklar-upplev-friheten-med-avancerad-teknik-och-svensk-design"
    }}>{`Crescent Elcyklar: Upplev Friheten med Avancerad Teknik och Svensk Design`}</h1>
    <p>{`Upptäck ett brett utbud av Crescent elcyklar på vår e-handel och hitta den perfekta modellen som passar dina behov! Crescent är kända för sin innovativa design, högkvalitativa komponenter och miljövänliga teknologi. Varje cykel är byggd för att leverera en överlägsen körupplevelse, vare sig du pendlar till jobbet, utforskar naturen eller bara njuter av en lugn tur i staden.`}</p>
    <h2 {...{
      "id": "utforska-crescent-elcykel-serien"
    }}>{`Utforska Crescent Elcykel Serien`}</h2>
    <h3 {...{
      "id": "crescent-elora-serien"
    }}>{`Crescent Elora-serien`}</h3>
    <p><strong parentName="p">{`Perfekt för Stadspendling och Fritidskörning`}</strong></p>
    <p>{`Crescent Elora är en mångsidig elcykel med komfort och funktion i fokus. Utrustad med en robust EGOING mittmotor och ett kraftfullt 400Wh batteri, erbjuder Elora smidig assistans upp till 25 km/h och en räckvidd på upp till 70 km. De 7 växlar och låga insteget gör den idealisk för stadskörning och längre turer. `}</p>
    <h4 {...{
      "id": "nyckelfunktioner"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`Kraftfull EGOING mittmotor`}</li>
      <li parentName="ul">{`Räckvidd på upp till 70 km`}</li>
      <li parentName="ul">{`TFT-display med USB-kontakt`}</li>
      <li parentName="ul">{`Integrerad belysning`}</li>
      <li parentName="ul">{`Bekvämt lågt insteg`}</li>
    </ul>
    <h3 {...{
      "id": "crescent-elina-serien"
    }}>{`Crescent Elina-serien`}</h3>
    <p><strong parentName="p">{`Optimal Komfort för Daglig Pendling`}</strong></p>
    <p>{`Elina passar dem som söker en pålitlig och stilfull elcykel med sin framhjulsmotor från EGOING och batteriet som ger upp till 70 km räckvidd. Med 7 växlar och både fotbroms och skivbroms, får du full kontroll och säkerhet oavsett väder och vägförhållanden.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner-1"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`EGOING framhjulsmotor`}</li>
      <li parentName="ul">{`Räckvidd på upp till 70 km`}</li>
      <li parentName="ul">{`Fotbroms och skivbroms`}</li>
      <li parentName="ul">{`LCD-display med USB`}</li>
      <li parentName="ul">{`Ergonomisk design med lågt insteg`}</li>
    </ul>
    <h3 {...{
      "id": "crescent-elsa-serien"
    }}>{`Crescent Elsa-serien`}</h3>
    <p><strong parentName="p">{`Elegant och Effektiv för Långa Avstånd`}</strong></p>
    <p>{`Med Crescent Elsa får du en elcykel som är både elegant och kraftfull. Med Shimano STEPS motor och ett batteri som erbjuder upp till 70 km räckvidd, är Elsa perfekt för både pendling och helgäventyr. Dess låga insteg och robusta konstruktion gör den lätt att hantera och pålitligt säker.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner-2"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`Shimano STEPS mittmotor`}</li>
      <li parentName="ul">{`Räckvidd på upp till 70 km`}</li>
      <li parentName="ul">{`Integrerad LCD-display`}</li>
      <li parentName="ul">{`Lågt insteg för enkel hantering`}</li>
      <li parentName="ul">{`Stilren design`}</li>
    </ul>
    <h3 {...{
      "id": "crescent-elder-serien"
    }}>{`Crescent Elder-serien`}</h3>
    <p><strong parentName="p">{`För den Äventyrslystna Cyklisten`}</strong></p>
    <p>{`Crescent Elder är hybridserien för dig som vill ha en sportigare cykling. Med sin kraftfulla Shimano STEPS E6100 mittmotor och ett batteri på 500Wh, få du en imponerande räckvidd och prestanda både i stadsmiljö och tuffare terränger. De avancerade skivbromsarna och 10 växlar garanterar säkerhet och kontroll.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner-3"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`Shimano STEPS E6100 mittmotor`}</li>
      <li parentName="ul">{`Räckvidd mellan 50-80 km`}</li>
      <li parentName="ul">{`10 växlar och högpresterande skivbromsar`}</li>
      <li parentName="ul">{`Stabil 28-tums hjulstorlek`}</li>
      <li parentName="ul">{`Integrerad LED-belysning`}</li>
    </ul>
    <h3 {...{
      "id": "crescent-elmer-serien"
    }}>{`Crescent Elmer-serien`}</h3>
    <p><strong parentName="p">{`Kombination av Stadscykling och Terrängäventyr`}</strong></p>
    <p>{`Elmer är ett utmärkt val för både stadspendling och äventyr på grusvägar. Med sin Shimano STEPS E6110 motor och ett batteri på 400Wh, erbjuder den en räckvidd på 4-7 mil. Den är utrustad med femväxlad Shimano Nexus drivlina och robusta skivbromsar, vilket ger en säker och bekväm åkning.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner-4"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`Shimano STEPS E6110 motor`}</li>
      <li parentName="ul">{`Integrerat batteri på 400Wh`}</li>
      <li parentName="ul">{`Fem växlar och Shimano Nexus drivlina`}</li>
      <li parentName="ul">{`Hydrauliska skivbromsar`}</li>
      <li parentName="ul">{`Användarvänlig LCD-display`}</li>
    </ul>
    <h3 {...{
      "id": "crescent-elis-serien"
    }}>{`Crescent Elis-serien`}</h3>
    <p><strong parentName="p">{`Perfekt Hybrid för Pendling och Fritid`}</strong></p>
    <p>{`Elis är perfekt för dig som söker en mångsidig hybrid elcykel. Med EGOING mittmotor och nio växlar, får du kraftfull assistans och flexibilitet för både stad och landsväg. Dess integrerade belysning och robusta konstruktion gör den till ett pålitligt val oavsett väder.`}</p>
    <h4 {...{
      "id": "nyckelfunktioner-5"
    }}>{`Nyckelfunktioner:`}</h4>
    <ul>
      <li parentName="ul">{`EGOING mittmotor`}</li>
      <li parentName="ul">{`Räckvidd på upp till 70 km`}</li>
      <li parentName="ul">{`Shimano Alivio 9-växlad drivlina`}</li>
      <li parentName="ul">{`Integrerad belysning och ramlås`}</li>
      <li parentName="ul">{`Skivbromsar för säkerhet`}</li>
    </ul>
    <h2 {...{
      "id": "köpguide-vilken-crescent-elcykel-passar-dig"
    }}>{`Köpguide: Vilken Crescent Elcykel Passar Dig?`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`För daglig pendling och stadskörning`}</strong>{`: Satsa på Crescent Elora eller Elina, kända för sin komfort och användarvänlighet.`}</li>
      <li parentName="ul"><strong parentName="li">{`För både pendling och längre turer`}</strong>{`: Crescent Elsa erbjuder elegans och räckvidd för alla tillfällen.`}</li>
      <li parentName="ul"><strong parentName="li">{`För äventyr på olika terränger`}</strong>{`: Välj Crescent Elder eller Elmer, båda med kraftfulla mittmotorer och stabil konstruktion.`}</li>
      <li parentName="ul"><strong parentName="li">{`För hybridcykling mellan stad och landsväg`}</strong>{`: Crescent Elis kombinerar det bästa av båda världar, med mångsidighet och prestanda.`}</li>
    </ul>
    <p>{`Upptäck din nästa Crescent elcykel hos oss och upplev kvalitet och innovation i varje pedaltramp. Crescent Elcyklar erbjuder något för varje cyklist, med hög prestanda, säkerhet och stil. Välj en Crescent elcykel som passar dina behov och upplev friheten på två hjul!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      